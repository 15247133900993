import React, { useEffect, useState } from "react";
import { PdfViewer } from "../../components/pdf-viewer/PdfViewer";
import generatePropsFromAttributes from '../parser/utils/generatePropsFromAttributes';
import {extractPropsFromDataset} from "./propsHelpers";

export const pdfViewer = (node, index, children, { resolveValue, model }) => {
  const {attribs} = node;
  const props = extractPropsFromDataset(attribs);
  const { url, width, height, isFileId } = props;
  let urlData = url;
  if (url.startsWith('{') && url.includes('}')) urlData = resolveValue(model, urlData);

  return (
    <div {...generatePropsFromAttributes(attribs, index)} id="pdf-viewer-form">
      <PdfViewerResolver urlData={urlData} width={width} height={height} isFileId={isFileId} />
    </div>
  )
}

const PdfViewerResolver = ({urlData, width, height, isFileId}) => {
  const [dataProps, setDataProps] = useState({});

  useEffect(() => {
    if(isFileId !== undefined) setDataProps({fileId: urlData});
    else setDataProps({url: urlData});
  }, [isFileId, urlData]);
  
  return (
    <PdfViewer size={{width: width, height: height}} {...dataProps} />
  );
};