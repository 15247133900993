import React, {useCallback, useEffect, useMemo, useState} from "react";
import "./editable-map.scss";
import { mapCartographies } from "../Geoman/utils";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem } from "reactstrap";
import { t } from "@lingui/macro";
import GeoEditableMap from "./GeoEditableMap";
import { extractFeatures } from "./geoJsonUtils";

const optionsItem = [
  { label: t`All`, value: 'all' },
  { label: t`None`, value: 'none' },
  { label: t`Invert`, value: 'invert' },
];

const EditableMap = ({ height, onChange, value, layers, zoom, cartographies, center, streetView }) => {
  const [dataLayers, setDataLayers] = useState();
  const [layerIndexEdit, setLayerIndexEdit] = useState();
  const [initialEdit, setInitialEdit] = useState(false);

  const [layersVisibility, setLayersVisibility] = useState(() => {
    const visibility = { 0: true };
    layers && layers.forEach(
      (layer, index) => visibility[index] = true);
    return visibility;
  });

  const dataCartographies = useMemo(() => {
    if(cartographies){
      let cartographyNames = cartographies.split(',');
      let data = cartographyNames.map(c => (mapCartographies.find(m => m.value === c)));
      return data;
    }
    return;
  }, [cartographies]);

  const mapCenter = useMemo(() => {
    if(center) { return center.split(',').map(n => parseFloat(n)); }
    return;
  }, [center]);

  const onEditLayer = useCallback((index) => {
    if(value){
      if(layerIndexEdit !== undefined){
        if(window.confirm(t`Cancel current changes?`)) {
          setLayerIndexEdit(index);
        }
      }else setLayerIndexEdit(index);
    }
  }, [value, layerIndexEdit]);

  // create geoJson structure
  useEffect(() => {
    let collection = {type: 'FeatureCollection', features: []};
    if (value) {
      if(Array.isArray(value)){
        value.forEach((l, index) => {
          if (layersVisibility[index] === true) {
            let color = layers[index].color || '#0000';
            let cluster = layers[index].cluster;
            let data = extractFeatures(l, {color, key: index, cluster});
            if(data) data.forEach(d => collection.features.push(d));
          };
        });
      }
    }
    setDataLayers(collection);
  }, [value, layersVisibility, layers]);

  // activate editing automatically
  useEffect(() => {
    if(dataLayers && dataLayers.features.length > 0 && !initialEdit){
      let positionEdit;
      for (const key in layers) {
        if(!layers[key].readOnly){
          positionEdit = parseInt(key);
          break;
        }
      }
      if(positionEdit !== undefined) {
        if(value[positionEdit]){
          onEditLayer(positionEdit);
          setInitialEdit(true);
        }else setInitialEdit(false);
      }else setInitialEdit(true);
    }
  }, [dataLayers, onEditLayer, layers, value, initialEdit]);

  const updateGeoJson = useCallback((newGeoJson) => {
    let values = [...value];
    values && values.forEach((v, i) => {
      if(i === layerIndexEdit){
        values[i] = [newGeoJson];
      }
    });
    setLayerIndexEdit(undefined);
    setDataLayers();
    onChange(values);
  }, [layerIndexEdit, layers, value, onChange]);

  const viewLayout = useCallback((e) => {
    const options = e.target.name;
    let visible = options === 'all';
    setLayersVisibility(current => {
      let visibility = {};
      Object.entries(current).forEach(([key, value]) => {
        visibility[parseInt(key)] = options === 'invert' ? !value : visible;
      });
      return visibility;
    });
  }, []);

  const toggleVisibility = useCallback((layerIndex) => {
    if(layerIndex !== -1){
      setLayersVisibility(current => {
        const visible = current[layerIndex];
        return {...current, [layerIndex]: !visible};
      });
    }
  }, []);

  const configMap = useMemo(() => ({
    cartographies: dataCartographies,
    layerControlPosition: "topright",
    zoomPosition: "bottomright" ,zoom, center: mapCenter,
    streetView
  }), [dataCartographies, mapCenter, zoom, streetView]);

  return (
    <div className="editable-map-wrapper" style={{ height: height || "100%", marginBottom: '3.5rem', marginTop: '1rem' }}>
      <div className="container-layers">
        <UncontrolledDropdown>
          <DropdownToggle color="link" className="widget-menu">
            <i className="fas fa-layer-group" />
          </DropdownToggle>
          <DropdownMenu style={{zIndex: 10000, width: "12rem"}}>
            <React.Fragment>
              <div className="options-item" >
                {optionsItem.map(e => <button type="button" className="btn btn-link" key={e.value} name={e.value} onClick={viewLayout}>{e.label}</button>)}
              </div>
              <DropdownItem divider />
              {layers && layers.map((layer, key) => {
                return(
                  <DropdownItem key={key} toggle={false} onClick={(e) => e.stopPropagation()}>
                    <div>
                      {!layer.readOnly &&
                        <i className="fas fa-pen"
                          style={{ marginRight: 10, fontSize: "80%", color: `${layerIndexEdit === key ? "var(--accentColor)" : "var(--secondaryText)"}`}}
                          onClick={() => onEditLayer(key)}
                        />
                      }
                      {layer.readOnly && <div style={{ width: 22.81, display: "inline-block" }} />}
                      {layersVisibility[key] && <i className="fas fa-check" style={{ marginRight: 10, fontSize: "80%", color: "var(--accentColor)" }} />}
                      {!layersVisibility[key] && <div style={{ width: 22.81, display: "inline-block" }} />}
                      <span onClick={() => toggleVisibility(key)}>{layer.title}</span>
                    </div>
                  </DropdownItem>
                )
              })}
            </React.Fragment>
          </DropdownMenu>
        </UncontrolledDropdown>
        {layers[layerIndexEdit] && <span>{layers[layerIndexEdit].title}</span>}
      </div>
      <div className="editable-map" style={{marginTop: '0.5rem'}}>
        <GeoEditableMap
          configMap={configMap}
          geoJson={dataLayers}
          editingLayerIndex={layerIndexEdit}
          onFinished={updateGeoJson}
          onCancel={setLayerIndexEdit}
          drawOptions={layers[layerIndexEdit] && layers[layerIndexEdit].drawOptions}
        />
      </div>
    </div>
  );
};

export default EditableMap;
