import React, {useMemo, useCallback, useEffect, useState, useRef} from 'react'
import {Trans, t} from "@lingui/macro";
import nanoid from "nanoid";
import {Api} from "../../api";
import Geoman from "../Geoman";
import {ResourceList,ZoneList} from "./components";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner, Col, Row, Alert,ModalFooter
} from "reactstrap";
import colors from "../../colors";
import FormRenderer from "../../forms/FormRenderer";
import InputCoord from "./components/InputCoord";
import {useSize} from "./hooks";
/*
import * as Utils from "../Geoman/utils";
const resources = [{
  "id":"61fd06a8-798e-313d-999a-ea61724ca597",
  "resourceName": "zoneEditor",
  "query": "select * from store.dynamic.sitrack.zone.editor" ,
  "geometryKey": "zona",
  "layerTitle": "zoneEditor",
  "drawOptions":[Utils.DRAW_OPTIONS.DRAW_MARKER, Utils.DRAW_OPTIONS.DRAW_POLYLINE,Utils.DRAW_OPTIONS.DRAW_RECTANGLE,Utils.DRAW_OPTIONS.DRAW_CIRCLE_MARKER,Utils.DRAW_OPTIONS.DRAW_CIRCLE],
  "fieldsToShow":"idCliente,nombre,codigo,direccion,region,comuna,estado,status",
  formId:"d83a8148-944a-4512-a0cb-2a5c92dace1c",
  iconUrl:"repair",
  detailFields:[{"nombre":"nombre"},{"COD":"codigo"}],
  filterOnFields:[{"nombre":"nombre"},{"idCliente":"COD"}]
  detailFields:[{"nombre":"nombre"},{"COD":"codigo"}]
},{
  "id":"b1203413-60c8-4a99-bd1a-af8f086a22e3",
  "resourceName": "supermercado",
  "query": "select * from store.dynamic.sitrack.supermercado order by _creationDate desc limit 20" ,
  "geometryKey": "zona",
  "layerTitle": "supermercado",
  "drawOptions":[Utils.DRAW_OPTIONS.DRAW_MARKER,Utils.DRAW_OPTIONS.DRAW_POLYLINE,Utils.DRAW_OPTIONS.DRAW_RECTANGLE,Utils.DRAW_OPTIONS.DRAW_CIRCLE_MARKER,Utils.DRAW_OPTIONS.DRAW_CIRCLE],
  "fieldsToShow":"idCliente,nombre,direccion,region,comuna,estado,status",
  formId:"d83a8148-944a-4512-a0cb-2a5c92dace1c",
  iconUrl:"",
  detailFields:[{"nombre":"nombre"},{"idCliente":"COD"}],
  filterOnFields:[{"nombre":"nombre"},{"idCliente":"CodCliente"}]
  detailFields:[{"nombre":"nombre"},{"idCliente":"COD"}]
}]
//{resources}
*/
const ZoneEditor = ({resources}) => {
  const allResources = useMemo(()=>{
    return resources.map(r => {
      let result;
      if(r.color){
        result = {...r,fillColor:r.color}
      }else{
        let index = Math.floor(Math.random() * (colors.length-1))
        let rcolors = colors[index]
        result = {...r,color:rcolors[0],fillColor:rcolors[2]}
      }
      if(!result.id)
        result.id = nanoid()
      return result;
    })
  },[resources]);
  const [resourcesData,setResourcesData] = useState([]);
  const [displayZones,setDisplayZones] = useState(null);
  const [resourcesQueryExecuted,setResourcesQueryExecuted] = useState([]);
  const [resourcesVisibility, setResourcesVisibility] = useState([]);
  const [resourceToAddFeature,setResourceToAddFeature] = useState(null);
  const [zoneSelected,setZoneSelected]=useState(null);
  const [isOpenEditZone,setIsOpenEditZone] = useState(false);
  const [loadingZones,setLoadingZones] = useState(false);
  const [centerTo,setCenterTo] = useState(null);
  const [newZone,setNewZone] = useState(null);
  const [mapHeight,setMapHeight]=useState(400);
  const target = useRef(null);
  const size = useSize(target);
  const [map,setMap]=useState(null);
  const [dialogForm,setDialogForm] = useState(null);
  const [isLoadingForm,setIsLoadingForm] = useState(false);
  const [clearStack, setClearStack] = useState(null);
  const [alertConfig, setAlertConfig] = useState({isOpen:false,color:'success',msg:''});
  const [isSaving, setIsSaving] = useState(false);
  const [isOpenModalConfirmForm, setIsOpenModalConfirmForm] = useState(false);
  const anyChangeOnGeometries = useRef(false);
  const zoneToSelect = useRef(null);
  const canOpenEditModal = useRef(true);
  const eventTypeRef = useRef('edit');

  const onInitMap = useCallback(newMap => {
    setMap(newMap)
  },[]);

  useEffect(()=>{
    if(map) {
      map.invalidateSize();
    }
    const parent =document.getElementById('container-form-loader').parentNode;
    let containerHeight = window.innerHeight / 1.4;
    if(parent.classList.contains("fullscreen-enabled")){
      containerHeight = parent.offsetHeight - 80
    }
    setMapHeight(containerHeight);
  },[size,map]);

  useEffect(() => {
    if(alertConfig.isOpen === true){
      let timer = setTimeout(()=>{
        setAlertConfig({isOpen:false,color:'success',msg:''})
      },2000)

      return () => {
        clearTimeout(timer)
      }
    }
  },[alertConfig.isOpen])

  const runOneQuery = useCallback( async (query) => {
    try {
      let result =  await Api.query(query);
      return {status:true,result};
    }catch (err){
      return {status:false,error:err};
    }
  },[])

  const executeQuery = useCallback(() => {
    allResources.forEach((resource,index) => {
      if(resourcesVisibility[index]){
        setLoadingZones(true)
        if(resource.query !== resourcesQueryExecuted[index]){
          Api.query(resource.query)
            .then(res =>{
              if(res.length > 0) {
                setResourcesData(current =>{
                  let rd = [...current]
                  rd[index] = {data:res,resourceId:resource.id}
                  return rd
                })
              }

              setResourcesQueryExecuted(current => {
                let rq = [...current]
                rq[index] = resource.query
                return rq
              })
              setLoadingZones(false);
            })
        }else{
          setLoadingZones(false);
        }
      }
    })
  },[allResources,resourcesVisibility])

  useEffect(executeQuery,[resourcesVisibility])

  const onToggleVisibility = useCallback((checked,resource,pos)=>{
    setZoneSelected(null);
    if(checked){
      setResourceToAddFeature(resource);
    }else {
      if(resourceToAddFeature && allResources[pos].id === resourceToAddFeature.id)
        setResourceToAddFeature(false);
    }

    if(resource){
      let index = allResources.findIndex(r => r.id === resource.id)
      if(index !== -1){
        setResourcesVisibility(current => {
          let rv = [...current]
          rv[index] = checked
          if(!checked){
            let countVisible = rv.filter(el => el === true)
            if(countVisible.length === 1){
              let visibleIndex = rv.findIndex(el => el === true);
              setResourceToAddFeature(allResources[visibleIndex]);
            }
          }
          return rv
        })
      }
    }
  },[allResources,resourceToAddFeature])

  const onSelectResourceToAddFeature = useCallback((resource)=>{
    setResourceToAddFeature(resource)
    setZoneSelected(null)
  },[])

  const onSelectDisplayZonesHandler = useCallback((index,item)=>{
    setDisplayZones(true);
    setResourceToAddFeature(item);
    setLoadingZones(true);
    setZoneSelected(null);
    setResourcesVisibility(current => {
      let rv = [...current]
      rv[index]=true;
      return rv
    })
  },[])

  const onZoneBackHandler = useCallback(()=>{
    setDisplayZones(false)
    setResourceToAddFeature(null)
    setZoneSelected(null)
  },[])

  const scrollToZone = useCallback((zone,pos = undefined, datos = undefined) => {
    let data = (datos !== undefined? {data:datos} : resourcesData.find(rs => rs && rs.resourceId === resourceToAddFeature.id));
    if(data.data){
      let index = (pos === undefined ? data.data.findIndex(ele => ele.id === zone.id):pos)
      let zoneListContainer = document.getElementById('zoneListContainer');
     if(zoneListContainer){
       let childNodes = zoneListContainer.lastChild.childNodes
       let finalHeight = 40;
       let i = 0;
       while (i < index){
         let heightTemp =  window.getComputedStyle(childNodes[i], null).getPropertyValue('height')
         finalHeight += parseInt(heightTemp.substr(0,heightTemp.length-2))
         i++;
       }
       zoneListContainer.scrollTo(0,finalHeight)
     }
    }
  },[resourcesData,resourceToAddFeature])


  const onClickItemZone = useCallback((zone,index) => {
    if(!anyChangeOnGeometries.current){
      if(zoneSelected && zoneSelected.id === zone.id){
        setZoneSelected(null)
      }else {
        let pos = index;
        if(index === undefined && resourceToAddFeature){
          const data = resourcesData.find(rs => rs && rs.resourceId === resourceToAddFeature.id)
          const temp = data && data.data.find(feature => feature.id === zone.id)
          pos = data && data.data.findIndex(feature => feature.id === zone.id)
          temp && setZoneSelected(temp)
        }else{
          setZoneSelected(zone)
        }
        pos && scrollToZone(zone,pos);
      }
    }else{
      zoneToSelect.current = zone;
      canOpenEditModal.current = false;
      setIsOpenModalConfirmForm(true);
    }
  },[zoneSelected, resourcesData,resourceToAddFeature,scrollToZone,anyChangeOnGeometries.current])

  const loadDynamicForm = useCallback((formId)=>{
    setIsLoadingForm(true)
    Api.Form.get(formId)
      .then(form => {
        setDialogForm(form);
      })
      .finally(()=>{
        setIsLoadingForm(false)
      })
  },[]);

  const openModalEditZone = useCallback(feature => {
    if(resourceToAddFeature && resourceToAddFeature.formId){
      if(anyChangeOnGeometries.current === true){
        canOpenEditModal.current = true;
        zoneToSelect.current = feature;
        setIsOpenModalConfirmForm(true)
      }else{
        setIsOpenEditZone(true)
        loadDynamicForm(resourceToAddFeature.formId)
        setZoneSelected(feature)
      }
    }
  },[resourceToAddFeature,anyChangeOnGeometries.current])

  const closeModalEditZone = useCallback(()=>{
    setIsOpenEditZone(false)
    setClearStack(null);
  },[])

  const onClickDrawNewZone = useCallback(()=>{
    setZoneSelected(null)
  },[])

  const onCreateNewZone = useCallback(async (newZone) => {
    try {
      setIsSaving(true)
      let created = await  Api.StoreDynamic.create({
        _resourceName:resourceToAddFeature.resourceName,
        _strategy:"mongo",
        ...newZone
      });
      let queryResult = await runOneQuery(resourceToAddFeature.query);

      if(queryResult.status === true){

        let index = queryResult.result.findIndex(el => el.id === created.id)
        if(index > -1){
          setZoneSelected(queryResult.result[index]);
          setResourcesData(current => {
            let temp = [...current]
            let index = temp.findIndex(ele => ele && ele.resourceId === resourceToAddFeature.id)
            if(index > -1){
              temp[index].data = queryResult.result
            }
            return temp;
          })
        }
        setAlertConfig({color: 'success',isOpen: true,msg:t`Zone created successfully!`})
        return {success:true}
      }
      setAlertConfig({color: 'danger',isOpen: true,msg:t`An error has occurred, please check the data.`})
      return {success:false,error:''}
    }catch(err){
      console.error('created error:',err)
      setAlertConfig({color: 'danger',isOpen: true,msg:t`An error has occurred, please check the data.`})
      return {success:false,error:err}
    }finally{
      setIsSaving(false)
    }
  },[resourceToAddFeature])

  const onUpdateZone = useCallback(async (newData) => {

    try {
      setIsSaving(true)
      let updated = await Api.StoreDynamic.update({
        _resourceName:resourceToAddFeature.resourceName,
        _strategy:"mongo",
        ...newData
      });

      let queryResult = await runOneQuery(resourceToAddFeature.query)
      if(queryResult.status === true){
        setResourcesData(current => {
          let temp = [...current]
          let index = temp.findIndex(ele => ele && ele.resourceId === resourceToAddFeature.id)
          if(index > -1){
            temp[index].data = queryResult.result
          }
          return temp;
        })

        let index = queryResult.result.findIndex(el => el.id === updated.id)
        if(index > -1){
          setZoneSelected(queryResult.result[index]);
        }
        setAlertConfig({color: 'success',isOpen: true,msg:t`Zone updated successfully!`})
        scrollToZone(updated)
        return {success:true}
      }
      setAlertConfig({color: 'danger',isOpen: true,msg:t`An error has occurred, please check the data.`})
      return {success:false,error:''}
    }catch (err){
      console.error('Store dynamic update error:',err)
      setAlertConfig({color: 'danger',isOpen: true,msg:t`An error has occurred, please check the data.`})
      return {success:false,error:err}
    }finally {
      setIsSaving(false)
    }
  },[zoneSelected,resourceToAddFeature,runOneQuery,scrollToZone])

  const onDeleteZone = useCallback((zona) => {
    setIsSaving(true)
    return  Api.StoreDynamic.delete({
      id:zona.id,
      _resourceName:resourceToAddFeature.resourceName,
      _strategy:"mongo"
    }).then(()=>{
      setResourcesData(current => {
        let temp = [...current]
        let index = temp.findIndex(ele => ele && ele.resourceId === resourceToAddFeature.id)
        if(index > -1){
          temp[index].data = temp[index].data.filter(d => d.id !== zona.id)
        }
        setZoneSelected(null);
        return temp;
      })
      setAlertConfig({color: 'success',isOpen: true,msg:t`Zone removed successfully!`})
      return {success:true}
    }).catch(err => {
      console.error('delete zone error:',err)
      setAlertConfig({color: 'danger',isOpen: true,msg:t`An error has occurred, please check the data.`})
      return {success:false,error:err}
    }).finally(()=>{
      setIsSaving(false)
    })
  },[resourceToAddFeature])

  const onSave = useCallback(async (eventType,data)=>{
    if(eventType === 'add'){
      delete data.id
      return await onCreateNewZone({
        [resourceToAddFeature.geometryKey]:{
          features: [data],
          type: "FeatureCollection"
        }
      })
    }
    if(eventType === 'edit'){
      let zoneEdit = {
        [resourceToAddFeature.geometryKey]:{
          features: [data],
          type: "FeatureCollection"
        }
      }
      if(zoneSelected){
        zoneEdit = {...zoneSelected,[resourceToAddFeature.geometryKey]:{
            features: [data],
            type: "FeatureCollection"
          }}
        delete zoneEdit.customProperties;
      }
      return  await onUpdateZone(zoneEdit)

    }
    if(eventType === 'removeGeometry'){
      return await onUpdateZone({...zoneSelected,
        [resourceToAddFeature.geometryKey]:null
      })
    }

  },[zoneSelected,resourceToAddFeature,onCreateNewZone,onUpdateZone])

  const onCreateEditZoneWithForm = useCallback((newFeature,eventType = 'edit')=>{
    loadDynamicForm(resourceToAddFeature.formId);
    setNewZone(newFeature);
    setIsOpenEditZone(true);
    eventTypeRef.current = eventType
  },[resourceToAddFeature,loadDynamicForm])

  const onSubmitForm = useCallback(async (values,formikBag)=>{
    zoneToSelect.current = null;
    formikBag.setSubmitting(false);
    const {geometryKey} = resourceToAddFeature;
    if(newZone){
      delete newZone.id
    }
    setIsOpenEditZone(false);
    if(zoneSelected){
      if(newZone){
        values[geometryKey] = {
          features: [newZone],
          type: "FeatureCollection"
        };
      }
      delete values.customProperties;
      let result = await onUpdateZone(values)
      setClearStack(result)
    }else{
      let result = await onCreateNewZone({
        ...values,
        [geometryKey]:{
          features: [newZone],
          type: "FeatureCollection"
        }
      })
      setClearStack(result)
    }

  },[resourceToAddFeature,zoneSelected,newZone,onCreateNewZone,onUpdateZone])

  const centerToHandle = useCallback((latLng)=>{
    setCenterTo(latLng)
  },[])

  const hasGeometriesChanges = useCallback((hasChange) => {
    anyChangeOnGeometries.current = hasChange;
  },[])

  const confirmOpenForm = useCallback(() => {
    if(zoneToSelect.current !== null){
      setZoneSelected(zoneToSelect.current);
      zoneToSelect.current = null;
      setIsOpenModalConfirmForm(false);
      if(canOpenEditModal.current === true){
        loadDynamicForm(resourceToAddFeature.formId);
        setIsOpenEditZone(true);
      }
    }
  },[resourceToAddFeature,loadDynamicForm,zoneToSelect.current,canOpenEditModal.current])

  const onCancelFormEditZone = useCallback(() => {
    zoneToSelect.current = null;
    setIsOpenEditZone(false);
  },[])

  return <div ref={target} id="map-container" className="main full-content" >

    <div className="card" style={{minHeight: mapHeight}}>
      <div className="card-header">
        <div className={"d-flex justify-content-between"}>
          <div>{
            !displayZones?<span><Trans>Zones</Trans></span>:
              <div>
                <Button size={'sm'} color={'link'} onClick={onZoneBackHandler}>
                  <i className="fas fa-long-arrow-alt-left"/>
                </Button> {resourceToAddFeature && resourceToAddFeature.layerTitle}</div>
          }</div>
          {
            alertConfig.isOpen && <Alert className="pt-1 pb-0 mb-0" color={alertConfig.color}>
              {alertConfig.msg}
            </Alert>
          }
        </div>

      </div>
      <div className={"card-body"}>
        <Row className={"h-100"}>
          <Col xs={4} xl={3} className="d-flex flex-column h-100">
           <div className={'mb-3'}>
              <InputCoord centerTo={centerToHandle}/>
            </div>
            {
              displayZones ?
                <ZoneList
                  maxHeight={mapHeight}
                  loadingZones = {loadingZones}
                  data={resourcesData.find(rs => rs && rs.resourceId === resourceToAddFeature.id)}
                  zoneSelected={zoneSelected}
                  displayEdit={(resourceToAddFeature && resourceToAddFeature.formId)}
                  onClickItemZone={onClickItemZone}
                  openModalEditZone={openModalEditZone}
                  onClickDrawNewZone={onClickDrawNewZone}
                  onDeleteZone = { onDeleteZone }
                  isSaving = { isSaving }
                  scrollToZone = { scrollToZone }
                  resourceToAddFeature={resourceToAddFeature}
                />:<ResourceList
                  maxHeight={mapHeight}
                  data={allResources}
                  resourceToAddFeature={resourceToAddFeature}
                  resourcesVisibility={resourcesVisibility}
                  onSelectResource={onToggleVisibility}
                  onSelectResourceToAddFeature={onSelectResourceToAddFeature}
                  onSelectDisplayZones = {onSelectDisplayZonesHandler}
                />
            }
          </Col>
          <Col xs={8} xl={9}>
            <Geoman
              onInitMap={onInitMap}
              displayTBDraw={resourcesVisibility.length > 0 && resourcesVisibility.some(el => el === true) && resourceToAddFeature !== null && resourceToAddFeature !== false}
              displayZones={displayZones}
              resourceToAddFeature={resourceToAddFeature}
              resourcesVisibility={resourcesVisibility}
              resourcesData={resourcesData}
              centerTo={centerTo}
              zoneSelected={zoneSelected}
              onDoubleClickGeometry={onClickItemZone}
              onCreateEditZoneWithForm={onCreateEditZoneWithForm}
              onSaveHandler={onSave}
              allResources = {allResources}
              clearStack = {clearStack}
              hasGeometriesChanges = {hasGeometriesChanges}
            />
          </Col>
        </Row>
      </div>
    </div>
    <Modal isOpen={isOpenEditZone} toggle={closeModalEditZone}>
      <ModalHeader toggle={closeModalEditZone}><Trans>Edit Zone</Trans></ModalHeader>
      <ModalBody>
        {
          isLoadingForm && <Spinner/>
        }
        {
          isOpenEditZone === true && <FormRenderer
            submitDataObjects={false}
            form={dialogForm}
            onSubmit={onSubmitForm}
            onReset={onCancelFormEditZone}
            model={eventTypeRef.current === 'edit'? zoneSelected:null}
          />
        }
      </ModalBody>
    </Modal>
    <Modal isOpen={isOpenModalConfirmForm}>
      <ModalHeader className="px-4 py-3"><Trans>Unsaved changes</Trans></ModalHeader>
      <ModalBody className="py-0">
        <Trans>You have unsaved Geometry changes, if you continue you will lose them. Do you wish to continue?</Trans>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={confirmOpenForm}><Trans>Accept</Trans></Button>{' '}
        <Button color="secondary" onClick={() => setIsOpenModalConfirmForm(false)}><Trans>Cancel</Trans></Button>
      </ModalFooter>
    </Modal>
  </div>
}
export default ZoneEditor
