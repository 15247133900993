import React, { useEffect } from 'react'

const OAUTH_GOOGLE_ID = `${process.env.OAUTH_GOOGLE_ID || "483346621380-ss631jvrj99cu67ounp3fk268n4ht1lp.apps.googleusercontent.com"}`;
export const GoogleButton = ({googleLoginSuccess}) => {

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: OAUTH_GOOGLE_ID,
      callback: googleLoginSuccess
    });

    google.accounts.id.renderButton(
      document.getElementById('signInGoogleDiv'),
      {
        theme: "outline", size: "large", 
        longtitle: true, width: 360, shape: "pill"
      }
    )
  }, []);

  return (
    <div id='signInGoogleDiv'></div>
  )
}
