import { call, query } from "./calls";


const extraFields = `if(closedDate > 0, 'Closed', 'Open') as state, if(closedDate > 0, dateFormat(toDate(closedDate), 'dd/MM/yyyy'), '') as date`;

export const Milestone = {
    get: () => query(`SELECT *, ${extraFields} FROM Milestone`),
    search: (title) => query(`SELECT *, ${extraFields} FROM Milestone WHERE title like '${title}'`),
    searchOpen: (title) => query(`SELECT *, ${extraFields} FROM Milestone WHERE closedDate < 0 and title like '${title}'`),
    searchClosed: (title) => query(`SELECT *, ${extraFields} FROM Milestone WHERE closedDate > 0 and title like '${title}'`),
    getCountPage: (title, status) => query(`select * from Milestone underlying aggregation(
      filter((${status ? (status === 'open' ? 'closedDate < 0 and' : 'closedDate > 0 and') : ''} title like '${title}' and true)),
      group(_class, sum(1,'total'))
    )`),
    // getCountPage: (title, status) => query(`SELECT count(*) as total FROM Milestone WHERE ${status ? (status === 'open' ? 'closedDate < 0' : 'closedDate > 0') : ''} and title like '${title}'`),
    evaluatorSearch: (evaluator) => query(`SELECT * FROM Milestone WHERE closedDate < 0 ${evaluator} limit 20`),
    getId: (milestoneId) => query(`SELECT closedDate, dueDate, description, Milestone.id as id, title, startDate, corporateAccountId, manager, manager as accountId, productId,
     if(closedDate > 0, 'closed', 'open') as state, if(closedDate > 0, dateFormat(toDate(closedDate), 'dd/MM/yyyy'), '') as date,
     AccountList.name as accountName,
     (select name, id from Product where id in $productId) as products
     FROM Milestone LEFT JOIN AccountList ON manager = AccountList.id WHERE Milestone.id=${milestoneId}`),
    getMilestonesById: (milestoneId) => query(`SELECT * FROM Milestone WHERE id in (${milestoneId})`),
    create: (values) => call("/Milestone", "POST", values),
    update: (milestoneId, values) => call(`/Milestone/${milestoneId}`, "PUT", values),
    remove: (id) => call(`/Milestone/${id}`, "DELETE"),
    listeners: (id, body) => call(`/Milestone/${id}`, "PUT", { action: 'updateListeners', ...body}),
    getPage: (title, start, limit, status) => query(`SELECT *, if(closedDate > 0, 'Closed', 'Open') as state, if(closedDate > 0, dateFormat(toDate(closedDate), 'dd/MM/yyyy'), '') as date,
     if(dueDate > 0,  1, 0) as checkDate,(select aggregateSum(estimatedValue) as estimate from Issue where $id in milestoneId and estimatedValue != null group by a) as issueEstimate,
     (select count(*) as totalIssues from Issue where $id in milestoneId) as issueCount,
     if((size(issueEstimate) = 1),get(get(issueEstimate,0),'estimate'),0) as estimate, if((size(issueCount) = 1),get(get(issueCount,0),'totalIssues'),0) as totalIssues FROM Milestone
     WHERE ${status ? ((status === 'open' && 'closedDate < 0') || (status === 'closed' && 'closedDate > 0') || (status === 'all' && 'true')) : 'true'}
     AND ${title ? (`title like '${title}'`) : 'true'} order by checkDate desc, dueDate desc start ,${start} limit ${limit}`),
};
