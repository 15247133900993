import React, {useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState} from "react";
import moment from "moment";
import {Formik} from "formik";
import htmlparser2 from "htmlparser2";
import extractMetadata from "./layers/extractionLayers";
import "./FormRenderer.scss";
import {Spinner} from "reactstrap";
import {useZoneResources} from "./layers/zoneEditor";
import transformNodes from "./layers/transformNodes";
import {ConditionEvalSafe, resolveValue} from "./conditions";
import formRendererLayers from "./layers/formRendererLayers";
import { FileUploadContext, FileUploadProvider, getFieldName, getFileDataKey } from "./FileUploadContext";
import {transformSchemaToYup} from 'react-validation-builder';
import { getAccountId, getNowMapping, getTimeZoneCode } from '../account/accountUtils';
import { executeActions } from './executeActions';
import { useMapLayer } from "./layers/mapView";
import { cleanFeatures } from "../components/editable-map/geoJsonUtils";

const fieldsReducer = (fields, field) => {
  if (!fields.some(f => f.name === field.name)) {
    return [...fields, field];
  }
  return fields;
};

const queriesReducer = (queries, query) => {
  if (!queries.some(q => q.name === query.name)) {
    return [...queries, query];
  }
  return queries;
};

const mappingsReducer = (mappings, mapping) => {
  if (!mappings.some(m => m.key === mapping.key)) {
    return [...mappings, mapping];
  }
  return mappings;
};

const FormRendererComponent = ({ form, onModelUpdated ,model: initialModel, onSubmit: onSubmitCallback, onReset,
  submitDataObjects = true, preventFileSaving = false, fileKeyPrefix, onEvent }) => {
  const [localModel, setLocalModel] = useState({ ...initialModel });
  const [alertFileRequired, setAlertFileRequired] = useState();
  const [filesRequired, setFilesRequired] = useState([]);
  const [validationSchema, setValidationSchema] = useState();

  const onKeyUpdated = useCallback((key, value) =>
    setLocalModel(model => ({...model, [key]: value})), []);

  const [fields, updateFields] = useReducer(fieldsReducer, []);
  const [queries, updateQueries] = useReducer(queriesReducer, []);
  const [mappings, updateMappings] = useReducer(mappingsReducer, []);
  const [zoneResources, updateZoneResources] = useZoneResources();
  const [mapLayers, updateMapLayer] = useMapLayer();

  const [metadataExtracted, setMetadataExtracted] = useState(false);

  const fieldValues = useRef();
  
  const resolveExpression = (fieldKey, fieldValue, expression) => {
    if (!expression) return false;

    let values = fieldValues.current ? fieldValues.current : {};
    const extendedValues = { 
      ...values,
      _model: localModel,
      _accountId: getAccountId(),
      _timeZoneCode: getTimeZoneCode(),
      _now: getNowMapping() 
    };

    let condition = new ConditionEvalSafe({...extendedValues});
    return condition.run(expression);
  }

  const [nodes, setNodes] = useState([]);
  useEffect(() => {
    setMetadataExtracted(false);
    if (form) {
      setNodes(htmlparser2.parseDOM(form.html));
    } else {
      setNodes([]);
    }
  }, [form]);

  useEffect(() => {
    if(form && form.validationSchema){
      setValidationSchema(transformSchemaToYup(form.validationSchema, resolveExpression));
    }
  }, [form, localModel]);

  useEffect(() => {
    if (nodes && nodes.length > 0 && !metadataExtracted) {
      extractMetadata(nodes, { updateFields, updateQueries, updateMappings, updateMapLayer,updateZoneResources,artifactReferences: form.artifactReferences });
      setMetadataExtracted(true);
    }
  }, [nodes, metadataExtracted, updateFields, updateQueries, updateMappings, updateZoneResources, updateMapLayer]);


  const initialValues = useMemo(() => {
    const initialValues = {};
    fields.forEach(f => {
      if (initialModel && initialModel[f.name]) {
        if (f.type === 'datetime-local') {
          initialValues[f.name] = moment(initialModel[f.name]).format('YYYY-MM-DD HH:mm:ss');
        } else {
          initialValues[f.name] = initialModel[f.name];
        }
      } else {
        if (f.type === 'select') {
          initialValues[f.name] = f.defaultValue || '';
        } else {
          switch (f.typescriptType) {
            case 'string':
            default:
              initialValues[f.name] = f.defaultValue || '';
              break;
            case 'number':
              try {
                initialValues[f.name] = Number(f.defaultValue || '0');
              } catch (e) {
                initialValues[f.name] = 0;
              }
              break;
            case 'boolean':
              initialValues[f.name] = (f.defaultValue && f.defaultValue === 'true') || false;
              break;
            case 'array':
              initialValues[f.name] = f.defaultValue || [];
              break;
          }
        }
      }
    });
    return initialValues;
  }, [fields, initialModel]);

  const [pendingFormResult, setPendingFormResult] = useState();
  const { fileData, isUploading, isSaving, uploadFile, saveFile } = useContext(FileUploadContext);

  useEffect(() => {
    // remove file required alert
    setAlertFileRequired((current) => {
      let updateAlerts = {...current};
      Object.keys(fileData).forEach(f => {
        let key = getFieldName(fileKeyPrefix, f);
        updateAlerts[key] = false
      });
      return updateAlerts;
    });
  }, [fileData, fileKeyPrefix]);

  // runs only when there are files that have not been saved to the final bucket
  useEffect(() => {
    if (!pendingFormResult) return;
    const { result, formikBag } = pendingFormResult;
    let readyForSubmission = true;

    for (const fileKey in fileData) {
      const data = fileData[fileKey];
      if (data.fileName && !data.saved) {
        readyForSubmission = false;
        break;
      }
    }

    // if the files have already been uploaded to the final bucket, the onSubmitCallback function is executed
    if (readyForSubmission) {
      setPendingFormResult();
      onSubmitCallback(result, formikBag);
    }
  }, [pendingFormResult, fileData, onSubmitCallback]);

  const onSubmit = useCallback((values, formikBag) => {
    let result = submitDataObjects ? {...localModel, ...values} : {...initialModel, ...values};
    let waitingForFileSaving = false;
    for (const key in result) {
      const value = result[key];
      const field = fields.find(f => f.name === key);
      if (field && field.type === 'file') {
        const fileKey = getFileDataKey(fileKeyPrefix, field.name);
        if (fileData[fileKey]) {
          if (filesRequired.length > 0) {
            //remove required file field when validating that it exists
            let findIndex = filesRequired.findIndex(n => n === field.name);
            if (findIndex !== -1) filesRequired.splice(findIndex, 1);
          }
        }
      } else if (value === '') {
        if (field && (field.type !== 'string' && field.type !== 'text')) {
          result[key] = null;
        }
      }else if (field && field.type === "editable-map" && value){
        let configLayers = mapLayers[field.name];
        let theCollection = {};

        // if there is only one layer, the object is uploaded
        if(configLayers.length === 1){
          if(!configLayers[0].readOnly){
            theCollection = value[0][0];
            theCollection.features = cleanFeatures(theCollection.features);
          }
        }else{
          //check if there is a single geoJson to send
          if (configLayers.filter(x => !x.readOnly || x.submitValue).length === 1){
            let findIndex = configLayers.findIndex(x => !x.readOnly || x.submitValue);
            theCollection = value[findIndex][0];
            theCollection.features = cleanFeatures(theCollection.features);
          }else{
            value.forEach((v, index) => {
              if (v[0]) {
                v[0].features = cleanFeatures(v[0].features);
                if (!configLayers[index].readOnly) {
                  // structure the geoJsons inside the dataKey
                  let dataKey = configLayers[index].dataKey;
                  theCollection = {...theCollection, [dataKey]: v[0]};
                } else if (configLayers[index].readOnly && configLayers[index].submitValue) {
                  // extract values from query if layer is not editable
                  let modelVariable = configLayers[index].modelVariable;
                  if (!modelVariable.startsWith('{')) modelVariable = '{' + modelVariable;
                  if (modelVariable.indexOf('}') === -1) modelVariable = modelVariable + '}';
                  let value = resolveValue({_model: localModel, _initialModel: initialModel}, modelVariable);
                  theCollection = {...theCollection, ...value};
                }
              }
            });
          }
        }
        result[key] = theCollection;
      }
    }

    //if there are required file fields that have not been uploaded, an alert is launched
    if (filesRequired.length > 0) {
      let alert = {};
      filesRequired.forEach(f => alert = { ...alert, [f]: true });
      setAlertFileRequired(alert);
      formikBag.setSubmitting(false);
      return;
    }

    /*if preventFileSaving is false, it means that it is identified as the parent and
    will be in charge of executing the function of saving the files in the final bucket.*/
    if (!preventFileSaving) {
      Object.keys(fileData).forEach(key => {
        if (!fileData[key].saved) {
          saveFile(fileData[key].fileName, true, key, fileData[key].fileId);
          waitingForFileSaving = true;
        }
      });
    }

    if (!waitingForFileSaving) onSubmitCallback(result, formikBag);
    else setPendingFormResult({ result, formikBag });
  }, [onSubmitCallback, localModel, initialModel, fields, fileData,
    saveFile, mapLayers, filesRequired, preventFileSaving, fileKeyPrefix]);

  const uploadFilePrefixedCallback = useCallback((file, fieldName) => {
    //saves the file to the temporary bucket.
    uploadFile(file, getFileDataKey(fileKeyPrefix, fieldName));
  }, [fileKeyPrefix, uploadFile]);


  const onActionInternalEvent = useCallback((extendedValues, setFieldValue) => {
    const executeProps = {name: extendedValues._eventName, extendedValues, setFieldValue, mappings, queries, onEvent, onKeyUpdated};
    let execute = executeActions({...executeProps});
    if(!execute) onEvent && onEvent(extendedValues._eventName, extendedValues._eventData);
  }, [onKeyUpdated, onEvent, mappings, queries]);


  if (metadataExtracted) {
    return (
      <div className="dynamic-form">
        <Formik initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                onReset={onReset}>
          {({isSubmitting, setFieldValue, values, errors, touched}) => {
            fieldValues.current = values;
            const model = ({...values, _model: localModel, _initialModel: initialModel});
            const onInternalEvent = (name, data) => {
              const extendedValues = { ...model, _accountId: getAccountId(), _timeZoneCode: getTimeZoneCode(),
                _now: getNowMapping(), _eventName: name, _eventData: data };
              onActionInternalEvent(extendedValues, setFieldValue);
            };

            return (
              <React.Fragment>
                <FileFieldUpdater setFieldValue={setFieldValue} fileData={fileData} fileKeyPrefix={fileKeyPrefix} />
                {(isSubmitting || isUploading) && <div className="working-indicator"><Spinner size="sm" color="primary" type="grow" /></div>}
                {transformNodes(nodes, formRendererLayers,{
                  onKeyUpdated, resolveValue,
                  uploads: {
                    isUploading, isSaving, uploadFile: uploadFilePrefixedCallback
                  },
                  isSubmitting, setFieldValue, values, touched,
                  model, queries, mappings, onModelUpdated, alertFileRequired,
                  submitDataObjects, zoneResources, mapLayers, setFilesRequired,
                  fileKeyPrefix,artifactReferences: form.artifactReferences,
                  onInternalEvent, errors
                })}
              </React.Fragment>
            );
          }}
        </Formik>
      </div>
    );
  }
  return null;
};

const FileFieldUpdater = ({ setFieldValue, fileData, fileKeyPrefix }) => {

  useEffect(() => {
    //if fileData exists, it will filter the field name to save its value.
    Object.keys(fileData).filter(key =>
      (fileKeyPrefix && key.startsWith(`#${fileKeyPrefix}`)) ||
      (!fileKeyPrefix && !key.startsWith('#')))
      .forEach(k => {
        const fieldName = getFieldName(fileKeyPrefix, k);
        const data = fileData[k];
        // update only when file was uploaded
        if (data && data.fileId && !data.saved) {
          setFieldValue(fieldName, data.fileId);
        }
      });
  }, [fileData, fileKeyPrefix, setFieldValue]);

  return null;
}

const FormRenderer = (props) => {
  const { preventFileSaving } = props;
  if(!preventFileSaving){
    return (
      <FileUploadProvider>
        <FormRendererComponent {...props} />
      </FileUploadProvider>
    );
  }

  return <FormRendererComponent {...props} />;
}

export default FormRenderer;
