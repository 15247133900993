import React, { useCallback, useState, useEffect } from 'react';
import TemplateLogin from './TemplateLogin';
import { t, Trans } from '@lingui/macro';
import { FormGroup, Input, Label, Button, Spinner, UncontrolledTooltip } from 'reactstrap';
import { Api } from '../api';
import { Link } from 'react-router-dom';

export const RecoveryMail = () => {
  const [identifier, setIdentifier] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [notify, setNotify] = useState({});

  const onSubmit = useCallback(() => {
    setDisabled(true);
    Api.Account.ckeckIdentifier({identifier}).then(res => {
      if(res) setNotify({message: t`The link was sent successfully, check your email`, color: "#7975f7"});
    }).catch(err => {
      setDisabled(false);
      setNotify({message: t`An error ocurred, please try again.`, color: "#f86f6f", ok: false});
      console.log("error checking identifier: ", err);
    });
  }, [identifier]);

  return (
    <TemplateLogin title={t`Restore Password`}>
      <FormGroup>
        <Label for="identifier"><Trans>Enter your email/username</Trans></Label>
        <Input id="identifier"
          type="text"
          name="identifier"
          value={identifier}
          onChange={e => setIdentifier(e.target.value)} />
      </FormGroup>
      <Button disabled={disabled} type="submit" className="rounded-pill mt-4 mb-4" onClick={onSubmit}>
        <Trans>OK</Trans>
      </Button>
      <span style={{color: notify.ok }}>{notify.message}</span>
    </TemplateLogin>
  )
}

export const RestorePassword = (props) => {
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState();

  const token = props.match.params.token;

  useEffect(() => {
    if(token){
      Api.Account.checkToken(token).then(res => {
        if(res){
          setLoading(false);
          setIsValid(true);
        }
      }).catch(err => {
        setLoading(false);
        setIsValid(false);
      })
    }else{
      setLoading(false);
      setIsValid(false);
    }
  }, [props]);

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    setLoading(true);
    setDisabledSubmit(true);
    let body = { newPassword, passwordConfirm };
  
    Api.Account.passwordReset(body, token).then(res => {
      if(res) {
        setMessage({ok: true, value: t`Password changed successfully` });
        setLoading(false);
      }
    }).catch(err => {
      setMessage({ok: false, value: err});
      setLoading(false);
      setDisabledSubmit(false);
    });

  }, [newPassword, passwordConfirm]);

  
  useEffect(() => {
    if(newPassword !== ''){
      const regex = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{6,16}$/;
      if(newPassword === passwordConfirm && regex.test(newPassword)) setDisabledSubmit(false);
      else setDisabledSubmit(true);
    } else setDisabledSubmit(true);
  }, [newPassword, passwordConfirm]);

  return (
    <TemplateLogin title={t`Restore Password`} loading={loading}>
      {loading && <span className="text-center"><Spinner /></span>}
      {isValid && !message &&
        <React.Fragment>
          <FormGroup>
            <Label for="new-password"><Trans>New Password</Trans>{' '}
              <i className='far fa-question-circle' id="tooltip-password-help"></i>
            </Label>
            <UncontrolledTooltip style={{ textAlign: "left" }} target="tooltip-password-help" placement="right">
              <ul style={{ paddingLeft: 15, marginBottom: 0 }}>
                <li><Trans>6 and 16 characters</Trans></li>
                <li><Trans>At least one capital letter</Trans></li>
                <li><Trans>At least one lowercase</Trans></li>
                <li><Trans>At least one digit</Trans></li>
                <li><Trans>It may have other symbols</Trans></li>
              </ul>
            </UncontrolledTooltip>
            <Input id="new-password"
              type="password"
              name="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword( e.target.value)} />
          </FormGroup>
          <FormGroup>
            <Label for="password-confirm"><Trans>Confirm Password</Trans></Label>
            <Input id="password-confirm"
              type="password"
              name="password-confirm"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)} />
          </FormGroup>
          <Button disabled={disabledSubmit} type="submit" className="rounded-pill mt-4 mb-4" onClick={onSubmit}>
            <Trans>OK</Trans>
          </Button>
        </React.Fragment>
      }
      {!isValid && !loading && <span className='text-center'>{t`Error, token expired`}</span>}
      {message && message.ok &&
        <React.Fragment>
          <span className='text-center'>{message.value}</span>
          <Link to="/login" style={{textAlign: "center"}}>
            <Button type="button" style={{width: "12rem"}} className="rounded-pill mt-4 mb-4"><Trans>Back to login</Trans></Button>
          </Link>
        </React.Fragment>
      }
      {message && !message.ok && <span className='text-center'>{message.value}</span>}

    </TemplateLogin>
  )
}