import { t, Trans } from "@lingui/macro";
import React, {useEffect, useState} from "react";
import {getAccountId} from "../../../account/accountUtils";
import {Api} from "../../../api";
import BasicCredentialsEditor from "./BasicCredentialsEditor";

const UserCredentials = () => {
  const accountId = getAccountId();

  const [credentials, setCredentials] = useState([]);
  useEffect(() => {
    console.log('running credentials effect');
    Api.Credentials.getActiveByAccountId(accountId)
      .then(res => setCredentials(res))
      .catch(err => alert(t`Error searching credentials: ` + err));
  }, [accountId]);

  return (
    <div>
      {credentials.map(cred => {
        switch (cred.methodName) {
          case 'google':
            return (
              <div key={cred.id}>
                <h1 className="display-1 text-center">
                  <i className="fab fa-google" />
                </h1>
                <p className="text-center mt-4"><Trans>You log in with your Google account</Trans></p>
              </div>
            );
          case 'basic':
            return (
              <div key={cred.id}>
                <h1 className="display-2 text-center">
                  <i className="fas fa-key" />
                </h1>
                <BasicCredentialsEditor credentials={cred} />
              </div>
            );
          default:
            return <div/>;
        }
      })}
    </div>
  );
};

export default UserCredentials;
