import React, {useCallback, useState, useEffect, useMemo} from "react";
import {
  Button,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  TabPane,
  Label, Row, Col
} from "reactstrap";
import UserCredentials from "./UserCredentials";
import { TabPaneCustom } from "../../../components/tab-pane/TabPaneCustom";
import { getAccountId } from "../../../account/accountUtils";
import { AlertNotify } from "../../../components/alert-notify/AlertNotify";
import { t, Trans } from "@lingui/macro";
import FormRenderer from "../../../forms/FormRenderer";
import { Api } from "../../../api";

const UserAccountModal = ({isOpen, onDone}) => {
  const [infoAccount, setInfoAccount] = useState({});
  const [notify, setNotify] = useState();
  const [formAccountCustomLayout, setFormAccountCustomLayout] = useState();
  const [customFields, setCustomFields] = useState({});
  const [dataAccount, setDataAccount] = useState();

  const tabOptions = useMemo(() => ([
    { value: 1, label: t`Credentials` },
    { value: 2, label: t`Account IDs` },
    { value: 3, label: t`Custom Layout` }
  ]), []);

  const optionsCopy = useMemo(() => ([
    {label: t`Account ID`, value: infoAccount.accountId},
    {label: t`Corporate ID`, value: infoAccount.corporateId}
  ]), [infoAccount]);

  useEffect(() => {
    let id = getAccountId();
    let corporate = localStorage.getItem('currentSession') && JSON.parse(localStorage.getItem('currentSession'));
    setInfoAccount({accountId: id, corporateId: corporate ? corporate.corporateId : ""})
    getTabCustomLayout(corporate);
    Api.Account.get(id).then(res => {
      setCustomFields(res.customFields)
      setDataAccount(res);
    })
  }, []);

  const getTabCustomLayout = (session) => {
    if(session && session.corporateCustomLayout && session.corporateCustomLayout.accountCustomLayout){
      Api.Crud.get(session.corporateCustomLayout.accountCustomLayout).then(setFormAccountCustomLayout);
    }
  }

  const onCopy = useCallback((e) => {
    let id = e.target.id;
    navigator.clipboard.writeText(id);
    setNotify({message: t`Account ID copied`, color: 'success'});
  }, []);

  const onModelUpdated = useCallback(setCustomFields, []);
  const onEditAccount = useCallback(() => {
    if(customFields){
      Api.Account.update(infoAccount.accountId, {"_command": {
          "command": "edit-custom-fields",
          "payload": {
            "accountId": infoAccount.accountId,
            "customFields": customFields
          }}}).then(res => {
        setNotify({message: t`Account edited successfully`, color: 'success'});
      }).catch(err => {
        setNotify({message: t`Error editing the account`, color: 'danger'})
      });
    }
  }, [customFields, infoAccount, dataAccount]);

  const onFinishedNotify = useCallback(() => setNotify(), []);

  return (
    <Modal isOpen={isOpen} size="lg">
      <ModalBody>
        <Container>
          <TabPaneCustom tabOptions={tabOptions} >
            <TabPane tabId={1}>
              <UserCredentials />
            </TabPane>
            <TabPane tabId={2}>
              <div className="container-accountIDs">
                {optionsCopy.map((e,index) => (
                  <React.Fragment key={index}>
                    <Label style={{ fontWeight: 'bold' }}>{e.label}</Label>
                    <div className="container-copy">
                      <Row>
                        <Col sm={10}><p className="text-muted">{e.value}</p></Col>
                        <Col sm={2}>
                          <i className="fas fa-copy"
                            style={{ cursor: 'pointer' }}
                            id={e.value}
                            onClick={onCopy} />
                        </Col>
                      </Row>
                    </div>
                  </React.Fragment>
                ))}
              </div>
              <AlertNotify open={notify} message={notify && notify.message} onFinished={onFinishedNotify} position="topRight" color={notify && notify.color}/>
            </TabPane>
            <TabPane tabId={3}>
              {formAccountCustomLayout &&
                <React.Fragment>
                  <FormRenderer model={customFields} form={formAccountCustomLayout} onModelUpdated={onModelUpdated} submitDataObjects={false} />
                  <Button color="primary" onClick={onEditAccount}><Trans>Save</Trans></Button>
                </React.Fragment>
              }
            </TabPane>
          </TabPaneCustom>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onDone}><Trans>Close</Trans></Button>
      </ModalFooter>
    </Modal>
  );
};

export default UserAccountModal;
