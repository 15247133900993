import { call, query } from "./calls";

const ISSUE_DETAIL = "SELECT id, nanoId, type, gitlabBranchName, gitlabProjectName, environment, estimatedValue, fileId, gitlabProjectId, issuesDependencyId, " +
    "title, branches, teamId, relatedCorporateId, creationDate, eta, assignee, tags, assigneeTest, projectId, description, state, priority, customFields, " +
    "Meeting.id as meetingId, Meeting.title as meetingTitle, author, lifeCycle, branchOfficeId, " +
    "(select id, title from Milestone where id in $milestoneId) as milestone, " +
    "(select id as fileId, completeName as fileName from File where id in $fileId) as files, " +
    "(select title, id from Issue where id in $issuesDependencyId) as issuesDependency, " +
    "(select name, id from Account where isNotNull($relatedCorporateId) and id=$relatedCorporateId) as relatedCorporate, " +
    "(select id, teamName as name from Team where isNotNull($teamId) and id=$teamId) as team, " +
    "(select id, name from IssueLifeCycle where isNotNull(lifeCycle) and id=$lifeCycle) as lifeCycleData ," +
    "(select id, name from BranchOffice where isNotNull(branchOfficeId) and id=$branchOfficeId) as branchOffice ," +
    "(select id, name from AccountList where id in ($author, $assignee, $assigneeTest)) as accounts FROM Issue ";

const FIELD_PRIORITY_ISSUE = "if(equals(priority, 'low'), 0) AS prioridadlow, if(equals(priority, 'medium'), 5) AS prioridadmedium , if(equals(priority, 'high'), 10) AS prioridadhigh";

const getDetailById = (id) => {
    return query(`${ISSUE_DETAIL} where Issue.id=${id}`).then(res => {
        if(res && res[0]) {
            let relatedCorporate = res[0].relatedCorporate;
            let team = res[0].team;
            let branchOffice = res[0].branchOffice;

            relatedCorporate = relatedCorporate[0] || undefined;
            team = team[0] || undefined;
            branchOffice = (branchOffice && branchOffice[0]) || undefined;

            res[0].relatedCorporate = relatedCorporate;
            res[0].team = team;
            res[0].branchOffice = branchOffice;
        }
        return res;
    });
}

export const Issue = {
    get: () => query(`SELECT *,${FIELD_PRIORITY_ISSUE} FROM Issue ORDER BY prioridadlow, prioridadmedium, prioridadhigh, creationDate DESC`),
    getPage: (start, limit) => query(`SELECT * FROM (SELECT *,${FIELD_PRIORITY_ISSUE} FROM Issue WHERE state != 'done' ORDER BY prioridadlow, prioridadmedium, prioridadhigh, creationDate DESC) as IssueOrder start ${start} limit ${limit}`),
    getDetailById,
    getById: (id) => query(`SELECT * FROM Issue  where id=${id}`),
    getByNanoId: (nanoId) => query(`SELECT id FROM Issue where nanoId='${nanoId}'`),
    getIssuesByMilestoneId: (milestoneId) => query(`SELECT *, AccountList.name as assigneeName FROM Issue LEFT JOIN AccountList ON Issue.assignee = AccountList.id  WHERE ${milestoneId} in Issue.milestoneId`),
    getTotalEstimatedValueByMilestone: (milestoneId) => query(`SELECT aggregateSum(estimatedValue) as totalEstimatedValue, milestoneId FROM Issue WHERE ${milestoneId} in milestoneId and estimatedValue != null GROUP BY a`),
    getIssuesByMeetingId: (meetingId) => query(`SELECT id, title FROM Issue WHERE meetingId=${meetingId}`),
    getIssuesDependency: (issuesId) => query(`SELECT title, id FROM Issue WHERE id IN (${issuesId})`),
    getComments: (issueId) => query(`SELECT body, id, commentFatherId, createdDate, authorId, fileId, (select completeName as fileName, id from File where id in $fileId) as files ,(select name, id from AccountList where id=$authorId) as author FROM Comment WHERE issueId=${issueId} ORDER BY createdDate`),
    getCommentById: (commentId) => query(`SELECT * From Comment WHERE id=${commentId}`),
    accounts: (q) => query(`SELECT * FROM IssueAccounts where email like '${q}'`),
    getAssigneesIssue: (assigneeIds) => query(`SELECT * FROM IssueAccounts WHERE gitlabUserId IN (${assigneeIds})`),
    search: (q="") => query(`SELECT * FROM Issue WHERE title like '${q.trim()}'`),
    evaluatorSearch: (evaluator) => query(`SELECT * FROM Issue ${evaluator} limit 20 left join AccountList on Issue.assignee = AccountList.id`),
    create: (values) => call("/Issue", "POST", values),
    createComment: (values) => call("/Comment", "POST", values),
    updateComment: (body, commentId) => call(`/Comment/${commentId}`, "PUT", { "_command": { "command": "update-comment", "payload": body } }),
    update: (issueId, values) => call(`/Issue/${issueId}`, "PUT", values),
    createBranch: (issueId) => call(`/Issue/${issueId}`, "PUT", { action: 'createIssueBranch' }),
    delete: (resourceName, id) => call(`/${resourceName}/${id}`, "DELETE"),
    evaluator: (evaluator) => query(`SELECT *,${FIELD_PRIORITY_ISSUE} FROM Issue ${evaluator} ORDER BY prioridadlow, prioridadmedium, prioridadhigh, creationDate DESC`),
    evaluatorPage: (evaluator, start, limit, orderRule) => query(`SELECT IssueOrder.*,AccountList.name as assigneeName, count(milestoneId) as milestonesCount FROM (SELECT *,${FIELD_PRIORITY_ISSUE} FROM Issue ${evaluator} ORDER BY prioridadlow, prioridadmedium, prioridadhigh DESC) as IssueOrder LEFT JOIN AccountList on IssueOrder.assignee = AccountList.id ${orderRule} start ${start} limit ${limit}`),
    getLengthWithEvaluator: (evaluator) => query(`select * from Issue underlying aggregation(
    ${evaluator && evaluator.trim().length > 0 ? `filter((${evaluator} and true)),` : ''}
    group(_class, sum(1,'length'))
  )`),
    // getLengthWithEvaluator: (evaluator) => query(`SELECT count(*) as length FROM Issue ${evaluator}`),
    getHistoryTrace: (id) => query(`SELECT *, (SELECT name, id FROM AccountList WHERE id in ($accountId,$assigneeTest,$assignFrom,$assignTo)) as accounts FROM (SELECT historyTrace FROM Issue WHERE id=${id}).historyTrace as h ORDER BY date DESC`),
    listeners: (id, body) => call(`/Issue/${id}`, "PUT", { action: 'updateListeners', ...body}),

};

