import React, {useCallback, useEffect, useRef, useState} from "react";
import * as L from "leaflet";
import _ from "lodash";
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.min';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { t } from "@lingui/macro";
import nanoid from "nanoid";
import LeafletMap from "../LeafletMap";
import {styles} from "../LeafletMap/mapUtils";
import {Icon} from "leaflet";
import {MAX_ZOOM,mapCartographies,DRAW_OPTIONS,getMarkerIcon,normalizeCoordinates} from "./utils";
let findPoint;

const Geoman = props => {
  const {resourcesVisibility,resourcesData,
    displayZones,zoneSelected, centerTo,resourceToAddFeature,
    displayTBDraw,onDoubleClickGeometry,onCreateEditZoneWithForm,onInitMap,onSaveHandler,clearStack,hasGeometriesChanges,allResources = null} = props

  const [map,setMap]=useState(null)
  const [initialBounds,setInitialBounds] = useState(null)
  const [layerCollections,setLayerCollections] = useState([])
  const [featureStack,setFeatureStack] = useState([])
  const [showTBDraw,setShowTBDraw] = useState(()=>{
    if(displayZones){
      if(zoneSelected && zoneSelected[resourceToAddFeature.geometryKey]){
        return false
      }
      if(zoneSelected && !zoneSelected[resourceToAddFeature.geometryKey]){
        return true
      }
    }else {
      return displayZones
    }
  })

  const [lastIndexSelected,setLastIndexSelected] = useState(-1)
  const layersGroup = useRef(null)

  useEffect(() => {
    hasGeometriesChanges && hasGeometriesChanges(featureStack.length > 0)
  },[featureStack,hasGeometriesChanges])

  const layerDblClick = useCallback((event) => {
    L.DomEvent.stopPropagation(event);
    if(map){
      map.closePopup()
      if(displayZones && resourceToAddFeature){
        let temp = resourcesData.find(el => el && el.resourceId === resourceToAddFeature.id)
        temp.data && temp.data.some(f => f.id === event.layer.id) && onDoubleClickGeometry && onDoubleClickGeometry({id:event.layer.id,...event.layer.toGeoJSON()},undefined)
      }else{
        map.setView(event.layer.getBounds().getCenter())
      }
    }
  },[map,onDoubleClickGeometry,displayZones,resourceToAddFeature,resourcesData])

  const layerClick = useCallback(event => {
    L.DomEvent.stopPropagation(event);
    const {extraInfo:{detailFields,zoneData}} = event.layer
    if(detailFields && detailFields.length > 0){
      let html = '<div class="container">'
      detailFields.forEach(detail => {
        let labels = Object.keys(detail)
        html += `<div class="row"><div class="col pl-0 text-capitalize font-weight-bold">${detail[labels[0]]}</div><div class="col pl-0">${zoneData[labels[0]]}</div></div>`
      })
      html +='</div>'
      let latLng = [0,0]
      if(event.layer.getBounds && event.layer.getBounds().getCenter){
        latLng = event.layer.getBounds().getCenter();
      }else
      if(event.layer.getLatLng){
        latLng = event.layer.getLatLng();
      }
      L.popup({minWidth:200,maxHeight:300})
        .setLatLng(latLng)
        .setContent(html)
        .openOn(map)
    }
  },[map,layerCollections])

  const delayClick = _.debounce((event)=>{
    if(event.type === 'click'){
      layerClick(event)
      return;
    }
    if(event.type === 'dblclick'){
      layerDblClick(event)
      return;
    }
  },250)

  useEffect(() => {
    if(map){
      layersGroup.current = L.featureGroup().addTo(map);
    }
  },[map])

  useEffect(() =>{
    if(map && layersGroup.current){
      layersGroup.current.on("click dblclick", delayClick);
    }
    return () => {
      layersGroup.current && layersGroup.current.off("click dblclick", delayClick);
    }
  },[map,zoneSelected,resourcesData,displayZones,layerCollections])

  useEffect(() => {
    if(clearStack && clearStack.success === true)
      setFeatureStack([])
  },[clearStack])

  useEffect(()=>{
    if(displayTBDraw !== showTBDraw)
      setShowTBDraw(displayTBDraw)
  },[displayTBDraw])

  const init=useCallback((newMap)=>{
    setMap(newMap)
    onInitMap && onInitMap(newMap)
  },[onInitMap])

  useEffect(()=>{
    const regex = new RegExp(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/);
    //remove marker if search field is null
    if(centerTo === '') map.removeLayer(findPoint);

    if(map && regex.test(centerTo)){
      if(findPoint) map.removeLayer(findPoint);
      findPoint = new L.marker(L.latLng(centerTo.split(',')));
      findPoint.setIcon(new Icon({ iconUrl: getMarkerIcon('marker'), iconSize: [20,50]})).addTo(map);
      map.fitBounds(L.latLng(centerTo.split(',')).toBounds(1),{maxZoom:10})
    }
  },[map,centerTo])

  useEffect(()=>{
    if(map){
      map.doubleClickZoom.disable()
      map.pm.setGlobalOptions({markerStyle:{
          icon:new Icon({
            iconUrl: getMarkerIcon(resourceToAddFeature && resourceToAddFeature.iconUrl),
            iconSize: [20,50],
          })
        }})
    }
  },[map,resourceToAddFeature])

  useEffect(()=>{
    if(map){
      map.eachLayer(layer => {
        if(layer.toGeoJSON)
          layer.remove()
      })
    }
  },[map,resourcesData])

  const getGeometryKey = useCallback(() => {
    for (let i = 0; i < resourcesData.length;i++){
      if(resourcesData[i] && resourcesData[i].data && resourcesData[i].data.some(el => el.id === zoneSelected.id)){
        return allResources[i].geometryKey
        }
    }
    return 'zona'
  },[allResources,resourcesData,zoneSelected])

  useEffect(() =>{
    if(map ){
      if(!resourcesVisibility.some(ele => ele ===true)){
        map.fitBounds([[0,0],[0,0]],{maxZoom:1})
      }else {

        if(zoneSelected && zoneSelected.id){
          let geometryKey = resourceToAddFeature ? resourceToAddFeature.geometryKey : getGeometryKey()
          let index = layerCollections.findIndex(l => l.id === zoneSelected.id)
          if(lastIndexSelected !== -1){
            layerCollections[lastIndexSelected] && layerCollections[lastIndexSelected].pm.disable()
          }
          if(index > -1 && zoneSelected[geometryKey]){
            layerCollections[index].pm.enable({
              allowSelfIntersection: false,
            });
          }

          setLastIndexSelected(index)

          const jsData = L.geoJSON(zoneSelected[geometryKey]);
          let bounds = jsData.getBounds()

          if(bounds && bounds.isValid()) {
            let zoom = MAX_ZOOM;
            if(zoneSelected[geometryKey] && zoneSelected[geometryKey].features[0].properties.shape === 'Circle'){
              zoom /= 2;
            }
            map.fitBounds(bounds, {maxZoom: zoom})
          } else {
            if(initialBounds && initialBounds.isValid()) {
              map.fitBounds(initialBounds,{maxZoom:MAX_ZOOM})
            }
          }
        }else {
          map.pm.disableGlobalEditMode()
          map.pm.addControls({
            editControls:false,
          })
          setLastIndexSelected(-1)
        }
      }
    }
  },[map,zoneSelected,resourcesVisibility,layerCollections])

  const addNewGeometryToZone = useCallback(({layer,shape}) => {
    const geoJson = layer.toGeoJSON();
    let feature;
    switch (shape){
      case "Circle":
        feature = {
          id:nanoid(),
          ...geoJson,
          properties: {
            shape: "Circle",
            radius: layer._mRadius,
            category: "default"
          }
        };break;
      case "CircleMarker":
        feature = {
          id:nanoid(),
          ...geoJson,
          properties: {
            shape: "CircleMarker",
            radius: layer._radius,
            category: "default"
          }
        };break;
      default: feature = {id:nanoid(),...geoJson};break
    }
    featureStack.push({layer,newLayer:feature,eventType:'add'})
  },[featureStack])

  useEffect(()=>{
    if(map){
      let refEvent= map.on('pm:create',addNewGeometryToZone)
      return () => {
        if(refEvent)
          map.off('pm:create',addNewGeometryToZone)
      }
    }
  },[map,zoneSelected,featureStack])

  const createNewLayer = useCallback((geoJsonData)=>{
    try {
      const theCollection = L.geoJson(geoJsonData,{
        style:styles,
        pointToLayer: (feature, latlng) => {
          if (feature.properties.radius) {
            if(feature.properties.shape === "Circle")
              return new L.circle(latlng, {radius:feature.properties.radius});
            return new L.circleMarker(latlng, {radius:feature.properties.radius});
          } else {
            return L.marker(latlng);
          }
        },
        onEachFeature: (feature, layer) => {
          setLayerCollections(current=>{
            let temp = [...current]
            layer.id = geoJsonData.id
            layer.extraInfo = geoJsonData.extraInfo
            temp.push(layer)
            return temp;
          })
          if(feature.geometry.type === 'Point' && !feature.properties.radius){
            layer.setIcon(new Icon({
              iconUrl: getMarkerIcon(geoJsonData.extraInfo.iconUrl),
            }));}
        },
      });
      if(resourceToAddFeature){
        theCollection.on('pm:edit', ({layer,shape})=> {
          if(!layer.cutted){
            let newLayer = layer.toGeoJSON();
            if(shape === "Circle"){
              newLayer.properties.radius = layer._mRadius;
            }
            setFeatureStack(current => {
              let fs = [...current]
              layer.id = geoJsonData.id
              fs.push({layer,newLayer})
              return fs
            })
          }
        });
        theCollection.on('pm:cut', ({layer,originalLayer}) => {
          let {geometry,...rest} =originalLayer.feature
          originalLayer.cutted = true
          const {properties:{color,fillColor}} = originalLayer.toGeoJSON()
          layer.feature = {...layer.feature,...rest}
          const l = {...layer.toGeoJSON(),properties:{color:color,fillColor:fillColor}}
          theCollection.addData(l)
          setFeatureStack(current => {
            let fs = [...current]
            fs.push({layer,newLayer:layer.toGeoJSON()})
            return fs
          })
          layer.remove()
        });
        theCollection.on('pm:remove', ({layer}) => {
          setFeatureStack(current => {
            let fs = [...current]
            let temp = layer.toGeoJSON();
            delete temp.geometry
            delete temp.properties
            fs.push({layer,eventType:'removeGeometry',newLayer:temp})
            return fs
          })
        });
      }
      layersGroup.current.addLayer(theCollection)
      theCollection.addTo(map);
      if(zoneSelected && geoJsonData.id === zoneSelected.id)
        theCollection.pm.enable({
          allowSelfIntersection: false,
        });
      return theCollection
    }catch (e){
      console.error('createNewLayer error:',e)
      console.error('createNewLayer error geoJsonData:',geoJsonData)
    }
  },[map,zoneSelected,layersGroup,resourceToAddFeature,resourcesData])

  const renderData = useCallback(()=>{
    if(map){
      map.eachLayer((layer) => {
        if(layer.toGeoJSON && layer.remove){
          layer.remove()
        }
      })

      layersGroup.current.clearLayers()
      let bounds = []
      setLayerCollections([])
      resourcesData && resourcesData.forEach((obj,index)=>{
        if(resourcesVisibility[index] && obj && obj.data){
          const {data} = obj;
          data.forEach(zone =>{
            if(zone[allResources[index].geometryKey]){
              const {id:resourceId,iconUrl,detailFields} =allResources[index]
              let newLayer = createNewLayer({
                    id:zone.id,
                    ...zone[allResources[index].geometryKey],
                    extraInfo:{resourceId:resourceId,
                      detailFields:detailFields,
                      iconUrl:iconUrl,
                      zoneData:zone
                    }
                });
              bounds.push(newLayer.getBounds());
            }
          })
        }
      })

      if(!zoneSelected && bounds.length > 0) {
        let initBounds = bounds[0]
        bounds.forEach((b, index) => {
          if (index > 0 && b.isValid()) {
            initBounds.extend(b);
          }
        })
        if (initBounds.isValid()) {
          map.fitBounds(initBounds,{maxZoom:MAX_ZOOM})
          if(!initialBounds) {
            setInitialBounds(initBounds);
          }
        }
      }
    }
  },[map,resourceToAddFeature,resourcesData,resourcesVisibility,createNewLayer,initialBounds,zoneSelected,allResources,onDoubleClickGeometry,layersGroup])

  useEffect(renderData,[map,resourcesData,resourcesVisibility,resourceToAddFeature])

  const onCancel = useCallback(() => {
    if(featureStack.length > 0){
      setFeatureStack([])
      renderData()
    }
  },[featureStack,renderData])

  const onUndo = useCallback(() => {

    if(featureStack.length > 0){
      let lc = [...layerCollections]
      let tempStack = [...featureStack]
      let index = lc.findIndex(l => l.id === tempStack[tempStack.length-1].layer.id)
      if(index !== -1){
        tempStack[tempStack.length-1].layer.remove()
        lc[index].remove()
        lc.splice(index,1)
        setLayerCollections(lc)
        setLastIndexSelected(lc.length)

        if(featureStack.length === 1){
          let js = {
            type: 'FeatureCollection',
            id: zoneSelected.id,
            ...zoneSelected.zona
          }
          createNewLayer(js)
          setFeatureStack([]);
        }

        if(featureStack.length > 1){
          const {newLayer,layer} = tempStack[tempStack.length-2]
          let js = {
            type: 'FeatureCollection',
            id: layer.id,
            features:[newLayer]
          }
          createNewLayer(js)
          tempStack.splice(tempStack.length-1,1)
          setFeatureStack(tempStack)
        }
      }else{
        onCancel();
      }
    }
  },[map, featureStack,layerCollections,createNewLayer,zoneSelected,onCancel,renderData,resourceToAddFeature])

  const onSave = useCallback( async ()=>{
    if(featureStack.length > 0){
      let feature = featureStack[featureStack.length-1]
      const {eventType='edit',newLayer} = feature;
      newLayer.geometry = normalizeCoordinates(newLayer.geometry)
      if(eventType === 'add' && resourceToAddFeature && resourceToAddFeature.formId){
        onCreateEditZoneWithForm && onCreateEditZoneWithForm(newLayer,eventType);
      }else{
        if(displayZones){
          let result = await onSaveHandler(eventType,newLayer)
          if(result.success === true)
            setFeatureStack([]);
        }else{
          alert(t`Operation not allowed. You can only add new Geometries, to edit you must enter the resource.`)
          onCancel()
        }
      }
    }
  },[displayZones,featureStack,resourceToAddFeature,onCreateEditZoneWithForm,onSaveHandler,onCancel])

  useEffect(onCancel,[zoneSelected]);

  const createCustomControl = useCallback(()=>{
    if(!map.pm.Toolbar.buttons.undoFeature){
      map.pm.Toolbar.createCustomControl(
        {
          name: "undoFeature",
          block: "custom",
          className: "fas fa-undo",
          title: t`Undo changes`,
          toggle:false,
          onClick:onUndo,
          doToggle: true,
          toggleStatus: false,
        });
    } else{
      map.pm.Toolbar.buttons.undoFeature._button.onClick = onUndo
    }

    if(!map.pm.Toolbar.buttons.saveFeature){
      map.pm.Toolbar.createCustomControl(
        {
          name: "saveFeature",
          block: "custom",
          className: "far fa-save",
          title: t`Save`,
          toggle:false,
          onClick:onSave,
          doToggle: true,
          toggleStatus: false,
        });
    } else{
      map.pm.Toolbar.buttons.saveFeature._button.onClick = onSave
    }
    if(!map.pm.Toolbar.buttons.cancelChanges) {
      map.pm.Toolbar.createCustomControl(
        {
          name: "cancelChanges",
          block: "custom",
          className: "fas fa-ban",
          title: t`Cancel changes`,
          toggle:false,
          onClick:onCancel,
          doToggle: true,
          toggleStatus: false,
        });
    }else {
      map.pm.Toolbar.buttons.cancelChanges._button.onClick = onCancel
    }
    map.pm.Toolbar._showHideButtons()
  },[map,onSave,onCancel,onUndo]);

  const getDrawOptions = useCallback(()=>{
    let drawOptions = {}
    if(resourceToAddFeature && resourceToAddFeature.drawOptions){
      Object.keys(DRAW_OPTIONS).forEach(option =>{
        drawOptions = {...drawOptions,[DRAW_OPTIONS[option]]:resourceToAddFeature.drawOptions.includes(DRAW_OPTIONS[option])}
      })
    }
    return drawOptions
  },[resourceToAddFeature])

  useEffect(()=>{
    if(map){
      map.pm.removeControls()
      const drawOptions = getDrawOptions();
      if(displayZones && zoneSelected){
        let editOptions = zoneSelected[resourceToAddFeature.geometryKey] ?
          {
            editControls:true,
            editMode:false,
            dragMode:true,
            cutPolygon:true,
            removalMode:true
          } : { editControls:false }
        map.pm.addControls({
          ...editOptions,
          ...drawOptions,
          drawControls:!zoneSelected[resourceToAddFeature.geometryKey],
          customControls:zoneSelected
        })
        createCustomControl();
      }else {
        if(displayTBDraw){
          map.pm.addControls({
            ...drawOptions,
            drawControls:true,
            customControls:true,
          })
          createCustomControl();
        }
      }
    }
  },[map,displayZones,displayTBDraw,zoneSelected,createCustomControl,getDrawOptions])

  return <div style={{height:'100%',width:'100%'}}>
    <LeafletMap
      initialHandle={init}
      cartographies={mapCartographies}
      layerControlPosition={'topright'}
    />
  </div>
}
export default Geoman
